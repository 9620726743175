<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container>
            <v-row>
                <v-col cols="12" lg="3">
                    <DashboardSidebar></DashboardSidebar>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card flat class="pa-4">
                        <div class="d-flex justify-space-between align-center">
                            <p class="mb-0 text-body-1 font-weight-medium">{{ $t('余额提现') }}</p>
                            <v-btn depressed color="primary" text to="/withdrawal">{{ $t('提现明细') }}</v-btn>
                        </div>
                        <v-divider class="my-4"></v-divider>
                        <v-row>
                            <v-col cols="12" lg="4" md="4">
                                <v-card color="#F7F7F7" class="d-flex align-center pa-4" flat>
                                    <img src="@/assets/images/template/with1.png" alt="">
                                    <div class="pl-4">
                                        <p class="text-h6 mb-1">$1231232</p>
                                        <p class="grey--text text--darken-3 mb-0">{{ $t('可提现金额') }}</p>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="4" md="4">
                                <v-card color="#F7F7F7" class="d-flex align-center pa-4" flat>
                                    <img src="@/assets/images/template/with2.png" alt="">
                                    <div class="pl-4">
                                        <p class="text-h6 mb-1">$1231232</p>
                                        <p class="grey--text text--darken-3 mb-0">{{ $t('可提现金额') }}</p>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="4" md="4">
                                <v-card color="#F7F7F7" class="d-flex align-center pa-4" flat>
                                    <img src="@/assets/images/template/with3.png" alt="">
                                    <div class="pl-4">
                                        <p class="text-h6 mb-1">$1231232</p>
                                        <p class="grey--text text--darken-3 mb-0">{{ $t('可提现金额') }}</p>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card>
                    <v-card flat class=" mt-4">
                        <v-tabs background-color="#F2F2F2" center-active   show-arrows
                            slider-color="transparent">
                            <v-tab v-for="(mitem, midx) in paytypelist" :key="midx" class="text-subtitle-1"
                                active-class="white">{{ mitem.text }}</v-tab>

                        </v-tabs>
                        <div class="pa-4">
                            <img src="@/assets/images/template/alipay.png" alt="">
                        </div>
                    </v-card>


                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>
<script>
import DashboardSidebar from '../../components/DashboardSidebar.vue'
export default {
    components: {
        DashboardSidebar
    },
    data() {
        return {
            paytypelist: [{ text: this.$t('提现到支付宝'), val: 1 },
            { text: this.$t('提现到银行卡'), val: 1 },
        ]

        }
    }
}
</script>
