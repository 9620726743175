<template>
  <div>
    <v-card flat class="text-center py-4" v-if="userInfo">
      <v-avatar size="60">
        <img :src="userInfo.avatar" alt="alt" v-if="userInfo.avatar!=''">
        <img src="@/assets/images/login/goodnull.png" alt="alt" v-else>
      </v-avatar>
      <p class="text-subtitle-1 mt-3 font-weight-medium">{{ userInfo.nickname }}</p>
      <p>UID:{{ userInfo.id }}</p>
      <p class="mb-2 text-left pl-12">
        <v-icon color="primary">mdi-phone-in-talk</v-icon><span class="pl-2">{{ userInfo.mobile }}</span>
      </p>
      <p class="mb-0 text-left pl-12">
        <v-icon color="primary">mdi-email</v-icon><span class="pl-2">{{ userInfo.email }}</span>
      </p>
    </v-card>
    <v-card flat class="text-center py-4 d-flex mt-4 justify-space-between px-6">
      <div class="text-center">
        <p class="text-h5 mb-0 font-weight-bold">{{ buyedTotal  }}</p>
        <p class="mb-0 mt-2">{{ $t('已购买模板') }}</p>
      </div>
      <div class="text-center">
        <p class="text-h5 mb-0 font-weight-bold">{{ selfTotal }}</p>
        <p class="mb-0 mt-2">{{ $t('自定义模板') }}</p>
      </div>
    </v-card>
    <v-card flat class="mt-4    ">
      <v-list dense class="py-0">
        <v-list-item-group v-model="selectedItem" color="primary" light>
          <v-list-item v-for="(item, i) in catelist" :key="i" @click="topage(item,i)">
           
              <v-list-item-icon class="mr-2">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" class="text-body-1"></v-list-item-title>

              </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props:{
    buyedTotal:String,
    selfTotal:String,
  },
  data() {
    return {
      catelist: [{ text: this.$t('个人信息'), icon: 'mdi-account-outline', href: '/user-index' },
      // { text: this.$t('余额提现'), icon: 'mdi-cash-usd-outline', href: '/balance' },
      // { text: this.$t('提现记录'), icon: 'mdi-clipboard-check-multiple-outline', href: '/withdrawal' },
      { text: this.$t('购买记录'), icon: 'mdi-clipboard-plus-outline', href: '/buyed' },
      { text: this.$t('消费记录'), icon: 'mdi-clipboard-plus-outline', href: '/consume' },
      { text: this.$t('我的收益'), icon: 'mdi-clipboard-check-outline', href: '/income' }
      ],
      selectedItem: 0,
      userInfo:null
    }

  },
  mounted(){
    this.selectedItem=sessionStorage.getItem('navKey')?parseInt(sessionStorage.getItem('navKey')):1;
    this.userInfo=this.$store.state.USERINFO;
  },
  methods:{
    topage(item,idx){
      this.$router.push({path:item.href});
      sessionStorage.setItem('navKey',idx);
    }
  }
}
</script>

<style lang="scss">
.dashboard-nav-link {
  color: #2b3445 !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-left: 4px solid #fff;
  margin-bottom: 20px;

  svg {
    width: 20px;
  }

  &.router-link-exact-active {
    color: #d23f57 !important;
    border-left: 4px solid #d23f57;
  }

  &:hover {
    color: #d23f57 !important;
    border-left: 4px solid #d23f57;
  }
}
</style>